import PropTypes from 'prop-types';

import {
  styled,
  Autocomplete as AutocompleteProto,
  TextField,
  Chip,
} from '@mui/material';

import React from 'react';

import { HFillContainerVH } from '../../containers';
import { Close } from '@mui/icons-material';
import { InfoText } from '../InfoText';

const StyledAutocomplete = styled(AutocompleteProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '1rem',
}));

const StyledChipContainer = styled(HFillContainerVH)`
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: ${props => props.margin ? props.margin : '0 0 1rem 0'};
  overflow-x: auto;
`;

const StyledChip = styled(Chip)(
  ({ theme }) => `
  border-color: ${theme.palette.primary.p40};
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  & .MuiSvgIcon-root {
    color: ${theme.palette.primary.p20};
  }
  `,
);

export const Autochips = props => {

  const { title, description, groupName, options, onChange, label, error, helperText, chipMargin, ...other} = props;
  const [ filteredOptions, setFilteredOptions ] = React.useState(options);
  const [ selectedOptions, setSelectedOptions ] = React.useState([]);
  const [ selectedValue, setSelectedValue ] = React.useState();
  const autobox = React.useRef();

  const onChangeCallback = React.useCallback((value) => {
    onChange(value);
  }, [onChange]);

  React.useEffect(() => {
    if (selectedValue != null) {
      setSelectedOptions(prev => [...prev, selectedValue]);
    }
  }, [selectedValue]);

  React.useEffect(() => {
    setFilteredOptions(options.filter(o => !selectedOptions.some(s => s.id === o.id)));
    onChangeCallback(selectedOptions);
  }, [options, selectedOptions, onChangeCallback]);

  const handleChipClick = (item) => {
    setSelectedOptions(prev => {
      return prev.filter(o => o.id !== item.id);
    });
  };

  return (
    <React.Fragment>
    
      <InfoText
        title={title}
        description={description}
        groupName={groupName}
      />

      <StyledAutocomplete
        {...other}
        ref={autobox}
        margin={'0'}
        options={filteredOptions}
        renderInput={(params) => (
          <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label} />
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        clearOnBlur
        value={selectedValue}
        onBlur={() => {
          autobox.current.inputValue = '';
        }}
        onChange={(_event, newValue) => {
          setSelectedValue(newValue);
        }}>
      </StyledAutocomplete>

      <StyledChipContainer
        margin={chipMargin}>
        { selectedOptions && selectedOptions.length > 0 && selectedOptions.map((item) => (
            <StyledChip
              key={item?.id}
              variant="outlined"
              label={item?.label}
              onDelete={() => handleChipClick(item)}
              deleteIcon={<Close />}
            />
          ))
        }
      </StyledChipContainer>

    </React.Fragment>
  );
};

Autochips.displayName = 'Autochips';

Autochips.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  label: PropTypes.string,
  mnemonic: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  chipMargin: PropTypes.string,
};

Autochips.defaultProps = {
  id: `Autochips-${Math.random()}`,
  title: '',
  description: '',
  groupName: '',
  mnemonic: '',
  value: '',
  label: 'Label',
  error: false,
  helperText: '',
  onChange: () => console.log('Autochips Changed'),
};
