export const ReportFilterMnemonic = {
  Year: 'YEAR',
  Month: 'MONTH',
  Week: 'WEEK',
  Day: 'DAY',
  School: 'SCHOOL',
  YearOfStudy: 'YEAROFSTUDY',
  Intern: 'INTERN',
  Mentor: 'MENTOR',
  MentorTaskType: 'MENTORTASKTYPE',
  InternTaskType: 'INTERNTASKTYPE',
  InternAssessmentTaskType: 'INTERNASSESSMENTTASKTYPE',
  LessonPlan: 'LESSONPLAN',
  SelfAssessment: 'SELFASSESSMENT',
};
