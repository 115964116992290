import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  LinearProgress,
  Container,
} from '@mui/material';

import {
  usePersons,
} from 'hooks';

import {
  Autochips,
} from 'ui/common';

const StyledContainer = styled(Container)(() => ({
  margin: '0 !important',
  padding: '0 !important',
  marginTop: '1rem !important',
  maxWidth: 'unset !important',
}));

const StyledProgress = styled(LinearProgress)(() => ({
  marginTop: '-1rem',
}));

export const MentorFilter = props => {

  const {loading, error, persons} = usePersons(['MENTOR', 'COACH']);

  const options = React.useMemo(() => {

    if (persons == null) {
      return [];
    }

    return persons.map(person => {
      return {
        id: person.id,
        label: `${person.givenName} ${person.surname}`,
      };
    });
  }, [
    persons,
  ]);

  return (

    <React.Fragment>

      <StyledContainer>
        <Autochips
          {...props}
          disabled={loading || error || props.disabled}
          key={'mentors-autochip'}
          chipMargin={'0 0 0 0'}
          label={`Mentors ${props.isRequired && '(Required)' || ''}`}
          options={options}
          onChange={(value) => props?.selectedOptions != value && props.onChange(value)}/>
      </StyledContainer>

      { loading &&
        <StyledProgress />
      }
    </React.Fragment>
  );
};

MentorFilter.displayName = 'MentorFilter';

MentorFilter.propTypes = {
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  selectedOptions: PropTypes.any,
  onChange: PropTypes.func,
};
