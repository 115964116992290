import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
  Grid,
} from '@mui/material';

import {
  getWeek,
} from 'date-fns';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  width: '100%',
}));

export const WeekFilter = props => {

  const {
    setDefaultField,
  } = props;

  const calculateWeeksOfYear = React.useMemo(() => {

    const startDateWeekRange = [];
    const endDateWeekRange = [];
    const year = new Date(0, 1).getFullYear();
    let currentDate = new Date(year, 0, 1);
  
    // Find the first Monday of the year
    while (currentDate.getDay() !== 1) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    // Iterate through the weeks of the year until the current date
    let weekNumber = 1;
  
    while (currentDate.getFullYear() === year) {
      // Create the week label for Monday
      const currentMondayMonth = currentDate.toLocaleString('default', { month: 'short' });
      const currentMondayWeekDay = currentDate.toLocaleString('default', { weekday: 'short' });
      const mondayLabel = `Week ${weekNumber} - ${currentMondayWeekDay}, ${currentMondayMonth} ${currentDate.getDate()}`;
  
      startDateWeekRange.push({
        title: mondayLabel,
        value: weekNumber,
      });
  
      // Calculate the Sunday of the same week
      const sundayDate = new Date(currentDate);
      sundayDate.setDate(currentDate.getDate() + 6);
      const currentSundayMonth = sundayDate.toLocaleString('default', { month: 'short' });
      const currentSundayWeekDay = sundayDate.toLocaleString('default', { weekday: 'short' });
  
      const sundayLabel = `Week ${weekNumber} - ${currentSundayWeekDay}, ${currentSundayMonth} ${sundayDate.getDate()}`;
  
      endDateWeekRange.push({
        title: sundayLabel,
        value: weekNumber,
      });
  
      // Move to the next Monday
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber++;
    }

    const currentWeek = Math.max(getWeek(new Date(), {weekStartsOn: 1}) - 1, 0);

    setDefaultField('startWeek', startDateWeekRange[currentWeek].value);
    setDefaultField('endWeek', endDateWeekRange[currentWeek].value);
  
    return {
      startRange: startDateWeekRange,
      endRange: endDateWeekRange,
      defaultStartWeek: startDateWeekRange[currentWeek],
      defaultEndWeek: endDateWeekRange[currentWeek],
    }
  }, [setDefaultField]);

  return (

    <React.Fragment>
      <Grid container paddingTop={'1rem'} paddingBottom={'1rem'} columnSpacing={2} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            defaultValue={calculateWeeksOfYear.defaultStartWeek.value}
            label={'Start Week'}
            error={props.error}
            helperText={props.helperText}
            onChange={(e) => props.onChange({'startWeek': e.target.value})}
            select>
            {calculateWeeksOfYear.startRange.map((option) =>
              <MenuItem key={option?.title} value={option?.value}>{option?.title}</MenuItem>
            )}
          </StyledTextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            defaultValue={calculateWeeksOfYear.defaultEndWeek.value}
            label={'End Week'}
            onChange={(e) => props.onChange({'endWeek': e.target.value})}
            select>
            {calculateWeeksOfYear.endRange.map((option) =>
              <MenuItem key={option?.title} value={option?.value}>{option?.title}</MenuItem>
            )}
          </StyledTextField>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

WeekFilter.displayName = 'WeekFilter';

WeekFilter.propTypes = {
  setDefaultField: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
