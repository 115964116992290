import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
  LinearProgress,
} from '@mui/material';

import {
  endOfYear,
  format,
  startOfYear,
} from 'date-fns';

import {
  useIdentity,
  useTaskList,
} from 'hooks';

import {
  IdentityGroupType,
} from 'enums';

import {
  tasksInternGetAssessmentsParams,
} from 'gql/task/queryParams';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '1rem',
  marginTop: '1rem',
}));

const StyledProgress = styled(LinearProgress)(() => ({
  marginTop: '-1rem',
}));

export const SelfAssessmentFilter = props => {

  const { user } = useIdentity({
    context: SelfAssessmentFilter.context,
  });

  const dateRange = React.useMemo(() => {

    const currentDate = new Date();

    const startDate = startOfYear(currentDate).toISOString();
    const endDate = endOfYear(currentDate).toISOString();

    return [startDate, endDate];

  }, []);

  const queryParams = React.useMemo(() => {
    return tasksInternGetAssessmentsParams({
      userId: user.id,
      dateRange,
    })
  }, [
    user,
    dateRange,
  ]);

  const {loading, error, taskList} = useTaskList({
    queryParams,
    userType: IdentityGroupType.Intern.toUpperCase(),
  });

  const options = React.useMemo(() => {

    if (taskList == null) {
      return [];
    }

    return taskList.map(task => {
      return {
        id: task.id,
        label: `${format(task.scheduledStartTimestamp, 'do MMMM')} | ${task.title} (${task.session})`,
      };
    });
  }, [
    taskList,
  ]);

  return (

    <React.Fragment>

      <StyledTextField
        {...props}
        disabled={loading || error}
        label={`Self Assessment ${props.isRequired && '(Required)' || ''}`}
        onChange={(e) => props.onChange(e.target.value)}
        select>
        {options.map((option) =>
          <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
        )}
      </StyledTextField>

      { loading &&
        <StyledProgress />
      }
    </React.Fragment>
  );
};

SelfAssessmentFilter.displayName = 'SelfAssessmentFilter';

SelfAssessmentFilter.propTypes = {
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
};

SelfAssessmentFilter.context = {
  component: SelfAssessmentFilter.displayName,
};
