import {
  gql,
} from '@apollo/client';

export const QueryAllReports = gql`
  query Reports($params: ReportQueryFilterParams) {
    reportQueryFilter(params: $params) {
      statusCode
      body {
        reports {
          ...ReportFragment
        }
      }
    }
  }

  fragment ReportFragment on Report {
    id
    title
    description
    mnemonic
    filters {
      title
      description
      mnemonic
      required
    }
  }

`;