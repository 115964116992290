import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
  Grid,
} from '@mui/material';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  width: '100%',
}));

const getFirstMonday = (year, month) => {
  const date = new Date(year, month, 1);
  const day = date.getDay();
  const diff = (day <= 1) ? 1 - day : 8 - day;
  date.setDate(date.getDate() + diff);
  return date;
}

const getLastMonday = (year, month) => {
  const date = new Date(year, month + 1, 0);
  let day = date.getDay();
  day = (day === 0) ? 7 : day;
  const diff = (day == 1) ?  0 : day - 1 ;
  date.setDate(date.getDate() - diff);
  return date;
}

const formatMonthDateLabel = (year, month , date) => {
  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  const monthOptions = { month: 'long' };
  
  const monthName = (new Date(year, month, 1)).toLocaleDateString('en-US', monthOptions);
  const dayLabel = date.toLocaleDateString('en-US', options);

  return `${monthName} - ${dayLabel}`;
}

export const MonthFilter = props => {

  const {
    setDefaultField,
  } = props;

  const calculateMonthsOfYear = React.useMemo(() => {
    const startDateMonthRange = [];
    const endDateMonthRange = [];
    const year = new Date(0, 1).getFullYear();
  
    for (let month = 0; month < 12; month++) {
      const firstMonday = getFirstMonday(year, month);
      const lastMonday = getLastMonday(year, month);
      const sundayOfLastWeek = new Date(lastMonday.setDate(lastMonday.getDate() + 6));
      
      startDateMonthRange.push({ value: month + 1, title: formatMonthDateLabel(year, month, firstMonday) });
      endDateMonthRange.push({ value: month + 1, title: formatMonthDateLabel(year, month, sundayOfLastWeek) });
    }

    const currentMonth = new Date().getMonth();

    setDefaultField('startMonth', startDateMonthRange[currentMonth].value);
    setDefaultField('endMonth', endDateMonthRange[currentMonth].value);
  
    return { 
      startRange: startDateMonthRange, 
      endRange: endDateMonthRange,
      defaultStartMonth: startDateMonthRange[currentMonth],
      defaultEndMonth: endDateMonthRange[currentMonth],
    };
  }, [
    setDefaultField,
  ]);

  return (

    <React.Fragment>
      <Grid container paddingTop={'1rem'} paddingBottom={'1rem'} columnSpacing={2} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            defaultValue={calculateMonthsOfYear.defaultStartMonth.value}
            label={'Start Month'}
            error={props.error}
            helperText={props.helperText}
            onChange={(e) => props.onChange({'startMonth': e.target.value})}
            select>
            {calculateMonthsOfYear.startRange.map((option) =>
              <MenuItem key={option.title} value={option.value}>{option.title}</MenuItem>
            )}
          </StyledTextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            defaultValue={calculateMonthsOfYear.defaultEndMonth.value}
            label={'End Month'}
            onChange={(e) => props.onChange({'endMonth': e.target.value})}
            select>
            {calculateMonthsOfYear.endRange.map((option) =>
              <MenuItem key={option.title} value={option.value}>{option.title}</MenuItem>
            )}
          </StyledTextField>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

MonthFilter.displayName = 'MonthFilter';

MonthFilter.propTypes = {
  setDefaultField: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
