import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  LinearProgress,
  Container,
} from '@mui/material';

import {
  useSchools,
} from 'hooks';

import {
  Autochips,
} from 'ui/common';

const StyledContainer = styled(Container)(() => ({
  margin: '0 !important',
  padding: '0 !important',
  marginTop: '1rem !important',
  maxWidth: 'unset !important',
}));

const StyledProgress = styled(LinearProgress)(() => ({
  marginTop: '-1rem',
}));

export const SchoolFilter = props => {

  const {loading, error, schools} = useSchools();

  const options = React.useMemo(() => {

    if (schools == null) {
      return [];
    }

    return schools.map(school => {
      return {
        id: school.id,
        label: school.title,
      };
    });
  }, [
    schools,
  ]);

  return (

    <React.Fragment>

      <StyledContainer>

        <Autochips
          {...props}
          disabled={loading || error}
          error={props.error}
          key={'schools-autochip'}
          chipMargin={'0 0 0 0'}
          label={`Schools ${props.isRequired && '(Required)' || ''}`}
          options={options}
          onChange={(value) => props?.selectedOptions != value && props.onChange(value)}/>

      </StyledContainer>

      { loading &&
        <StyledProgress />
      }
    </React.Fragment>
  );
};

SchoolFilter.displayName = 'SchoolFilter';

SchoolFilter.propTypes = {
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  selectedOptions: PropTypes.any,
  onChange: PropTypes.string,
};
