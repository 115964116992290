import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
} from '@mui/material';

import {
  getYear,
  subYears,
} from 'date-fns';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '1rem',
  marginTop: '1rem',
}));

export const YearFilter = props => {

  const {
    setDefaultField,
  } = props;

  const yearList = React.useMemo(() => {

    const years = [];

    const currentDate = new Date();
    const previousYear = getYear(subYears(currentDate, 1));
    const currentYear = getYear(currentDate);

    years.push(previousYear);
    years.push(currentYear);

    setDefaultField('year', currentYear);

    return years;
  }, [
    setDefaultField,
  ]);

  return (

    <React.Fragment>

      <StyledTextField
        onChange={(e) => props.onChange(e.target.value)}
        select>
        {yearList.map((option) =>
          <MenuItem key={option} value={option}>{option}</MenuItem>
        )}
      </StyledTextField>
    </React.Fragment>
  );
};

YearFilter.displayName = 'YearFilter';

YearFilter.propTypes = {
  setDefaultField: PropTypes.func,
  onChange: PropTypes.func,
};
