import React from 'react';

import {
  JourneyName,
  ReportFilterMnemonic,
  ScreenName,
} from 'enums';

import {
  Scene,
  SpinnerButton,
} from 'ui/common';

import {
  useGenerateReport,
} from 'hooks';

import {
  buildGenerateReportVariables,
} from 'common';

import {
  ReportDownloader,
} from 'ui/reporting/components';

import {
  InternFilter,
  InternTaskTypeFilter,
  InternAssessmentTaskTypeFilter,
  MentorFilter,
  MentorTaskTypeFilter,
  MonthFilter,
  SchoolFilter,
  WeekFilter,
  YearOfStudyFilter,
  YearFilter,
  ReportFilter,
  DayFilter,
  LessonPlanFilter,
  SelfAssessmentFilter,
} from 'ui/reporting/components/filters';

import {
  isEmpty,
} from 'lodash';

import {
  isAfter,
} from 'date-fns';

const defaultFormFields = {
};

export const Reporting = () => {

  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [errors, setErrors] = React.useState({});
  const [fileId, setFileId] = React.useState('');

  const [
    generateReport,
    {
      loading: generateLoading,
      error: generateError,
      data: generateData
    }
  ] = useGenerateReport();

  const handleFieldChange = (field, value, mnemonic) => {

    setFileId('');

    setFormFields((prevState) => {

      setErrors({});

      const newFields = {
        ...prevState,
        [field]: value,
      };

      return newFields;
    });

    setErrors(prevState => {

      if (field === 'report') {
        return {};
      }

      const newErrors = {
        ...prevState,
        [mnemonic]: undefined,
      };

      if (mnemonic === ReportFilterMnemonic.Intern) {
        newErrors[ReportFilterMnemonic.Mentor] = undefined;
      }

      if (mnemonic === ReportFilterMnemonic.Mentor) {
        newErrors[ReportFilterMnemonic.Intern] = undefined;
      }

      return newErrors;
    });
  };

  const handleValidation = (params, filterMap) => {

    if (!params || !filterMap) {
      return false;
    }

    const filters = Object.values(filterMap);

    const errorMap = {};

    for(let i = 0; i < filters.length; i++) {

      if (filters[i].required && (params[filters[i].mnemonic] == null || params[filters[i].mnemonic] === '')) {
        errorMap[filters[i].mnemonic] = 'This field is required';
      }

      if (filters[i].mnemonic === ReportFilterMnemonic.Month || filters[i].mnemonic === ReportFilterMnemonic.Week) {

        const startDate = params[filters[i].mnemonic]?.[0];
        const endDate = params[filters[i].mnemonic]?.[1];

        if (startDate > endDate) {
          errorMap[filters[i].mnemonic] = 'Start date cannot be after end date.';
        }
      }

      if (filters[i].mnemonic === ReportFilterMnemonic.Day) {

        const startDate = new Date(params[filters[i].mnemonic]?.[0]);
        const endDate = new Date(params[filters[i].mnemonic]?.[1]);

        if (isAfter(startDate, endDate)) {
          errorMap[filters[i].mnemonic] = 'Start date cannot be after end date.';
        }
      }
    }

    setErrors(errorMap);

    return isEmpty(errorMap);
  };

  const setDefaultField = React.useCallback((field, value) => {
    handleFieldChange(field, value);
  }, []);

  React.useEffect(() => {
    if (!generateLoading && !generateError && generateData) {
      setFileId(generateData.reportGenerateByMnemonic?.body?.reportFileId || '');
    }
  }, [generateLoading, generateError, generateData]);

  const handleGenerateReport = () => {

    const filterMap = formFields?.report?.filterMap;

    const filters = {};

    if (filterMap[ReportFilterMnemonic.Year] && formFields['year'] != null) {
      filters[ReportFilterMnemonic.Year] = formFields['year'];
    }

    if (filterMap[ReportFilterMnemonic.Month] && formFields['startMonth'] != null && formFields['endMonth'] != null) {
      filters[ReportFilterMnemonic.Month] = [formFields['startMonth'], formFields['endMonth']];
    }

    if (filterMap[ReportFilterMnemonic.Week] && formFields['startWeek'] != null && formFields['endWeek'] != null) {
      filters[ReportFilterMnemonic.Week] = [formFields['startWeek'], formFields['endWeek']];
    }

    if (filterMap[ReportFilterMnemonic.Day] && formFields['startDay'] != null && formFields['endDay'] != null) {
      filters[ReportFilterMnemonic.Day] = [formFields['startDay'], formFields['endDay']];
    }

    if (filterMap[ReportFilterMnemonic.School] && formFields['schools'] != null && formFields['schools'].length > 0) {
      filters[ReportFilterMnemonic.School] = formFields['schools'].map(s => s.id);
    }

    if (filterMap[ReportFilterMnemonic.YearOfStudy] && formFields['yearOfStudy'] != null && formFields['yearOfStudy'].length > 0) {
      filters[ReportFilterMnemonic.YearOfStudy] = formFields['yearOfStudy'].map(y => y.id);
    }

    if (filterMap[ReportFilterMnemonic.Intern] && formFields['interns'] != null && formFields['interns'].length > 0) {
      filters[ReportFilterMnemonic.Intern] = formFields['interns'].map(i => i.id);
    }

    if (filterMap[ReportFilterMnemonic.Mentor] && formFields['mentors'] != null && formFields['mentors'].length > 0) {
      filters[ReportFilterMnemonic.Mentor] = formFields['mentors'].map(m => m.id);
    }

    if (filterMap[ReportFilterMnemonic.MentorTaskType] && formFields['mentorTaskType'] != null && formFields['mentorTaskType'].length > 0) {
      filters[ReportFilterMnemonic.MentorTaskType] = formFields['mentorTaskType'].map(t => t.id);
    }

    if (filterMap[ReportFilterMnemonic.InternTaskType] && formFields['internTaskType'] != null && formFields['internTaskType'].length > 0) {
      filters[ReportFilterMnemonic.InternTaskType] = formFields['internTaskType'].map(t => t.id);
    }

    if (filterMap[ReportFilterMnemonic.InternAssessmentTaskType] && formFields['internAssessmentTaskType'] != null && formFields['internAssessmentTaskType'].length > 0) {
      filters[ReportFilterMnemonic.InternAssessmentTaskType] = formFields['internAssessmentTaskType'].map(t => t.id);
    }

    if (filterMap[ReportFilterMnemonic.LessonPlan] && formFields['lessonPlan'] != null) {
      filters[ReportFilterMnemonic.LessonPlan] = formFields['lessonPlan'];
    }

    if (filterMap[ReportFilterMnemonic.SelfAssessment] && formFields['selfAssessment'] != null) {
      filters[ReportFilterMnemonic.SelfAssessment] = formFields['selfAssessment'];
    }

    const params = {
      reportMnemonic: formFields?.report?.mnemonic,
      filterProperties: {
        filter: filters,
      },
    };

    const isValid = handleValidation(filters, filterMap);

    if (!isValid) {
      return;
    }

    generateReport(buildGenerateReportVariables(params));
  };

  return (

    <Scene
      headerVisible={true}
      footerVisible={true}
      title={ScreenName.Reporting}>

      <ReportFilter
        onChange={(e) => handleFieldChange('report', e)}/>

      { formFields.report != null &&

        <React.Fragment>

          { formFields.report?.filterMap[ReportFilterMnemonic.Year] != null &&
            <YearFilter
              setDefaultField={setDefaultField}
              onChange={(e) => handleFieldChange('year', e)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.Month] != null &&
            <MonthFilter
              setDefaultField={setDefaultField}
              error={errors[ReportFilterMnemonic.Month]}
              helperText={errors[ReportFilterMnemonic.Month]}
              onChange={(e) => {
                if (e['startMonth']) {
                  handleFieldChange('startMonth', e['startMonth'], ReportFilterMnemonic.Month);
                } else if (e['endMonth']) {
                  handleFieldChange('endMonth', e['endMonth'], ReportFilterMnemonic.Month);
                }
              }}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.Week] != null &&
            <WeekFilter
              setDefaultField={setDefaultField}
              error={errors[ReportFilterMnemonic.Week]}
              helperText={errors[ReportFilterMnemonic.Week]}
              onChange={(e) => {
                if (e['startWeek']) {
                  handleFieldChange('startWeek', e['startWeek'], ReportFilterMnemonic.Week);
                } else if (e['endWeek']) {
                  handleFieldChange('endWeek', e['endWeek'], ReportFilterMnemonic.Week);
                }
              }}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.Day] != null &&
            <DayFilter
              setDefaultField={setDefaultField}
              error={errors[ReportFilterMnemonic.Day]}
              helperText={errors[ReportFilterMnemonic.Day]}
              onChange={(e) => {
                if (e['startDay']) {
                  handleFieldChange('startDay', e['startDay'], ReportFilterMnemonic.Day);
                } else if (e['endDay']) {
                  handleFieldChange('endDay', e['endDay'], ReportFilterMnemonic.Day);
                }
              }}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.Mentor] != null &&
            <MentorFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.Mentor].required}
              error={errors[ReportFilterMnemonic.Mentor]}
              helperText={errors[ReportFilterMnemonic.Mentor] || (formFields?.interns && formFields?.interns.length > 0 && 'Remove selected interns to select a mentor.')}
              selectedOptions={formFields?.mentors}
              disabled={formFields?.interns && formFields?.interns.length > 0}
              onChange={(e) => handleFieldChange('mentors', e, ReportFilterMnemonic.Mentor)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.Intern] != null &&
            <InternFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.Intern].required}
              error={errors[ReportFilterMnemonic.Intern] != null}
              helperText={errors[ReportFilterMnemonic.Intern] || (formFields?.mentors && formFields?.mentors.length > 0 && 'Remove selected mentors to select an intern.' || '')}
              selectedOptions={formFields?.interns}
              disabled={formFields?.mentors && formFields?.mentors.length > 0}
              onChange={(e) => handleFieldChange('interns', e, ReportFilterMnemonic.Intern)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.School] != null &&
            <SchoolFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.School].required}
              error={errors[ReportFilterMnemonic.School]}
              helperText={errors[ReportFilterMnemonic.School]}
              selectedOptions={formFields?.schools}
              onChange={(e) => handleFieldChange('schools', e, ReportFilterMnemonic.School)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.YearOfStudy] != null &&
            <YearOfStudyFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.YearOfStudy].required}
              error={errors[ReportFilterMnemonic.YearOfStudy]}
              selectedOptions={formFields?.yearOfStudy}
              onChange={(e) => handleFieldChange('yearOfStudy', e, ReportFilterMnemonic.YearOfStudy)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.MentorTaskType] != null &&
            <MentorTaskTypeFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.MentorTaskType].required}
              error={errors[ReportFilterMnemonic.MentorTaskType]}
              selectedOptions={formFields?.mentorTaskType}
              onChange={(e) => handleFieldChange('mentorTaskType', e, ReportFilterMnemonic.MentorTaskType)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.InternTaskType] != null &&
            <InternTaskTypeFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.InternTaskType].required}
              error={errors[ReportFilterMnemonic.InternTaskType]}
              selectedOptions={formFields?.internTaskType}
              onChange={(e) => handleFieldChange('internTaskType', e, ReportFilterMnemonic.InternTaskType)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.InternAssessmentTaskType] != null &&
            <InternAssessmentTaskTypeFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.InternAssessmentTaskType].required}
              error={errors[ReportFilterMnemonic.InternAssessmentTaskType]}
              selectedOptions={formFields?.internAssessmentTaskType}
              onChange={(e) => handleFieldChange('internAssessmentTaskType', e, ReportFilterMnemonic.InternAssessmentTaskType)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.LessonPlan] != null &&
            <LessonPlanFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.LessonPlan].required}
              error={errors[ReportFilterMnemonic.LessonPlan]}
              onChange={(e) => handleFieldChange('lessonPlan', e, ReportFilterMnemonic.LessonPlan)}/>
          }

          { formFields.report?.filterMap[ReportFilterMnemonic.SelfAssessment] != null &&
            <SelfAssessmentFilter
              isRequired={formFields.report?.filterMap[ReportFilterMnemonic.SelfAssessment].required}
              error={errors[ReportFilterMnemonic.SelfAssessment]}
              onChange={(e) => handleFieldChange('selfAssessment', e, ReportFilterMnemonic.SelfAssessment)}/>
          }
          
        </React.Fragment>
      }

      
      {!fileId &&
        <SpinnerButton
          text='Generate'
          margin={'1rem 0 0 0'}
          disabled={Object.values(errors).some(v => v != null)}
          loading={generateLoading}
          onClick={handleGenerateReport}
        />
      }

      {!!fileId &&
        <ReportDownloader
          groupName={'Download Report'}
          reportFileId={fileId}/>
      }

    </Scene>
  );
};


Reporting.displayName = 'Reporting';

Reporting.context = {
  screen: ScreenName.Reporting,
  journey: JourneyName.Reporting,
  component: Reporting.displayName,
};
