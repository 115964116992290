import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
  LinearProgress,
} from '@mui/material';

import {
  useReports,
} from 'hooks';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginTop: '1rem',
  marginBottom: '1rem',
}));

const StyledProgress = styled(LinearProgress)(() => ({
  marginTop: '-1rem',
}));

export const ReportFilter = props => {

  const {loading, error, reportList} = useReports();


  return (

    <React.Fragment>

      <StyledTextField
        disabled={loading || error}
        label={'Report Type'}
        onChange={(e) => props.onChange(e.target.value)}
        select>
        {reportList && reportList.map((option) =>
          <MenuItem key={option.id} value={option}>{option.title}</MenuItem>
        )}
      </StyledTextField>
      { loading &&
        <StyledProgress />
      }
    </React.Fragment>
  );
};

ReportFilter.displayName = 'ReportFilter';

ReportFilter.propTypes = {
  onChange: PropTypes.string,
};
