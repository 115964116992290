import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  Grid,
} from '@mui/material';

import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import {
  AdapterDateFns,
} from '@mui/x-date-pickers/AdapterDateFnsV3';

import {
  enGB,
} from 'date-fns/locale';

import {
  endOfDay,
  isDate,
  startOfDay,
} from 'date-fns';

const StyledDatePicker = styled(MuiDatePicker)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginTop: '1rem',
  marginBottom: '1rem',
  width: '100%',
}));

export const DayFilter = props => {

  const {
    setDefaultField,
  } = props;

  const defaultDays = React.useMemo(() => {

    const startDay = startOfDay(new Date());
    const endDay = endOfDay(new Date());

    setDefaultField('startDay', startDay.toISOString());
    setDefaultField('endDay', endDay.toISOString());

    return {
      startDay,
      endDay,
    };
  }, [
    setDefaultField
  ]);

  return (

    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledDatePicker
              defaultValue={defaultDays.startDay}
              label={'Start Day'}
              format='d MMMM yyyy'
              slotProps={{
                textField: {
                  variant: 'outlined',
                  error: props.error,
                  helperText: props.helperText,
                },
              }}
              onChange={(e) => isDate(e) && props.onChange({'startDay': e.toISOString()}) || undefined}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledDatePicker
              defaultValue={defaultDays.endDay}
              label={'End Day'}
              format='d MMMM yyyy'
              onChange={(e) => isDate(e) && props.onChange({'endDay': e.toISOString()}) || undefined}/>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </React.Fragment>
  );
};

DayFilter.displayName = 'DayFilter';

DayFilter.propTypes = {
  setDefaultField: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
