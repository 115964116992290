import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  Container,
} from '@mui/material';

import {
  Autochips,
} from 'ui/common';

const StyledContainer = styled(Container)(() => ({
  margin: '0 !important',
  padding: '0 !important',
  marginTop: '1rem !important',
  maxWidth: 'unset !important',
}));

export const YearOfStudyFilter = props => {

  const options = [
    {id: 0, label: '0'},
    {id: 1, label: '1'},
    {id: 2, label: '2'},
    {id: 3, label: '3'},
    {id: 4, label: '4'},
    {id: 5, label: '5'},
    {id: 6, label: '6'},
    {id: 7, label: '7'},
  ];

  return (

    <React.Fragment>
      <StyledContainer>

        <Autochips
          {...props}
          key={'year-of-study-autochip'}
          chipMargin={'0 0 0 0'}
          label={`Year of Study ${props.isRequired && '(Required)' || ''}`}
          options={options}
          onChange={(value) => props?.selectedOptions != value && props.onChange(value)}/>

      </StyledContainer>
    </React.Fragment>
  );
};

YearOfStudyFilter.displayName = 'YearOfStudyFilter';

YearOfStudyFilter.propTypes = {
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  selectedOptions: PropTypes.any,
  onChange: PropTypes.string,
};
